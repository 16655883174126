import axios from 'axios';

//const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
//console.log(process.env.NODE_ENV);
/* let baseURL = 'http:localhost:8080';
if (!development) {
  baseURL = "https://basicemployeedirectorywebapi.azurewebsites.net/api"
} else {
  baseURL: 'http://localhost:8080/'
} */
/*  export default axios.create({
  baseURL:'http:localhost:8080',
});  */


export default axios.create({
// baseURL: 'https://api.cifdaqscan.io/' 
baseURL:`${process.env.REACT_APP_API_URL}`
});

