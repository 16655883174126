import React from "react";

const Validator = (props) => {

  return (
    <>
      <div style={{ minHeight: 250 + 'px' }}>
        <p className="coming_soon">Coming Soon.</p>
      </div>
    </>
  );
}

export default Validator;
