
function unixToTime(unixTimeStamp) {
    if (unixTimeStamp !== '') {
        let unix_timestamp = unixTimeStamp;
        var dateData = new Date(unix_timestamp * 1000);
        var seconds = Math.floor((new Date() - dateData) / 1000);

        var interval = seconds / 31536000;
        if (interval >= 1) {
            let years = Math.floor(interval);
            return years === 1 ? "1 year" : years + " years";
        }

        interval = seconds / 2592000;
        if (interval >= 1) {
            let months = Math.floor(interval);
            return months === 1 ? "1 month" : months + " months";
        }

        interval = seconds / 86400;
        if (interval >= 1) {
            let days = Math.floor(interval);
            return days === 1 ? "1 day" : days + " days";
        }

        interval = seconds / 3600;
        if (interval >= 1) {
            let hours = Math.floor(interval);
            return hours === 1 ? "1 hour" : hours + " hours";
        }

        interval = seconds / 60;
        if (interval >= 1) {
            let minutes = Math.floor(interval);
            return minutes === 1 ? "1 minute" : minutes + " minutes";
        }

        return seconds === 1 ? "1 second" : seconds + " seconds";
    } else {
        return "";
    }
}


function truncateHashString(string) {
    if (string !== '') {

        var first = string.substring(0, 8);

        var last = string.slice(- 8);

        return first + "..." + last;

    } else {

        return "";
    }
}

function websiteName() {
    return "CIFDAQ Blockchain Explorer"
}

export { unixToTime, truncateHashString, websiteName };