import axios from "axios";
import API from "./Api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addSubscriber = createAsyncThunk(
  "email/newsletterEmail",
  async (data) => {
    try {
      const response = await API.post("/subscriber/create", data);
      console.log("response data", response.data);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
