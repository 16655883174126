import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import AppRouter from './router/AppRouter';
import { Provider } from "react-redux";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <AppRouter />
    </Provider>
);