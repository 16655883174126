import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSubscriber } from '../services/subscriber.service';

const subscriberSlice = createSlice({
    name: "subscriber",
    initialState: {
        subscriberDetails: {
            isLoading: false,
            subscribed: {},
            error: {},
            message: ''
        }
    },
    reducers: {
        clearSuccessMessage: (state, payload) => {

        }
    },
    extraReducers: (builder) => {
        builder.addCase(addSubscriber.pending.type, (state, action) => {
            state.subscriberDetails.status = 'pending';
            state.subscriberDetails.isLoading = true;
        })

        builder.addCase(addSubscriber.fulfilled.type, (state, action) => {
            state.subscriberDetails.status = 'success';
            console.log('action.payload', action.payload);
            state.subscriberDetails.subscribed = action.payload ? action.payload : "";
            state.subscriberDetails.isLoading = false;
        })

        builder.addCase(addSubscriber.rejected.type, (state, action) => {
            state.subscriberDetails.status = 'failed';
            state.subscriberDetails.error = action.payload ? action.payload : "";
            state.subscriberDetails.isLoading = false;
        })
    }
})

export default subscriberSlice.reducer;

