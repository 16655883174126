import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div className="inner-row content_area">
            <div className="tabs">
                <section className="detail-block">
                    <div className="main-container-1">
                        <div className="inner-row-1">
                            <div class="error_page">
                                <h1>404</h1>
                                <p>Oops, something went wrong.<br />
                                    Sorry, we couldn't find your page.</p>
                                <Link className="btn btn-success" to='/'>Go back</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

