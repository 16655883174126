import React from "react";

const PrivacyPolicy = (props) => {
  return (
    <>
      <section className="search_block" style={{ height: 140 + "px" }}>
        <div className="main-container">
          <div className="inner-row">
            <h1>Privacy Policy</h1>
          </div>
        </div>
        <div className="bg_globe">
          <img src="/newimg/background_globe.svg" alt="" />
        </div>
      </section>
      <div className="main-container">
        <div className="inner-row content_area mt-5 privacyPolicy">
          <div style={{ minHeight: 500 + "px" }}>
            <p className="ml-2 text-center">
              <br />
              <br />
              <span>
                <strong>Last Update: 22nd Jan 2023</strong>
              </span>
            </p>

            <br />
            <p>&nbsp;</p>

            <p>
              {" "}
              <span>
                This Privacy Policy (this “Privacy Policy”) describes how us
                (together with its affiliates, (“CIFDAQ Wallet") collect, use,
                and disclose information about you in connection with your use
                of the Services and access to the Site, including when you use
                or interact with the Services or Site, post on any community
                feature, register for any event or promotional campaign,
                complete any survey or questionnaire or otherwise communicate
                with us through any channel (including but not limited to our
                social media or contact information provided below). Unless
                specifically defined in this Privacy Policy, all capitalized
                terms have the meaning given to them in the Terms of Use. This
                Privacy Policy does not apply to any products, services,
                websites, or content that are offered by third parties or have
                their own privacy policy (whether or not affiliated with
                CIFDAQ). Please note other CIFDAQ Services or Site or Content
                may also have their own additional Privacy Policy or Terms of
                Use. Please read this Privacy Policy carefully so you understand
                how we handle information about you. We may update this Privacy
                Policy from time to time. If we make any changes, we will change
                the Last Updated date above. We encourage you to periodically
                review the Privacy Policy for the latest information on our
                privacy practices. Any modifications to this Privacy Policy will
                be effective upon our posting of the updated terms. In all
                cases, your continued use of the Services or access to the Site
                following the posting of any updated Privacy Policy indicates
                your acceptance of the updated Privacy Policy.
              </span>
            </p>

            <ol start="1">
              <li>
                <span>
                  <strong>1. Personal Data And The Blockchain </strong>
                </span>
                <ol>
                  <li>
                    <span>
                      Blockchain technology, a type of distributed ledger
                      technology<strong>(DLT)</strong> , is at the core of our
                      business. Blockchains are decentralized and made up of
                      digitally recorded data in a chain of packages called
                      "blocks". The manner in which these blocks are linked is
                      chronological, meaning that the data is very difficult to
                      alter once recorded. Since the ledger may be distributed
                      all over the world (across several "nodes" which usually
                      replicate the ledger) this means there is no single person
                      making decisions or otherwise administering the system
                      (such as an operator of a cloud computing system), and
                      that there is no centralized place where it is located
                      either.
                    </span>
                    <br />
                  </li>
                  <li>
                    <span>
                      Accordingly, by design, a blockchain’s data cannot be
                      changed or deleted and is said to be "immutable". This may
                      affect your ability to exercise your rights such as your
                      right to erasure ("right to be forgotten"), or your rights
                      to object or restrict processing of your personal data.
                      Data on the blockchain cannot be erased and cannot be
                      changed. Although smart contracts may be used to revoke
                      certain access rights, and some content may be made
                      invisible to others, it is not deleted.
                    </span>
                    <br />
                  </li>
                  <li>
                    <span>
                      In certain circumstances, in the future, in order to
                      comply with our contractual obligations to you (such as
                      delivery of tokens or provision of other services) it will
                      be necessary to collect certain personal data, such as
                      your wallet address, onto the blockchain; this is done
                      through a smart contract and requires you to execute such
                      transactions using your wallet’s private key.
                    </span>
                    <br />
                  </li>
                  <li>
                    <span>
                      The ultimate decision to (a) transact on the blockchain
                      using your wallet address, as well as (b) share the public
                      key relating to your wallet address with anyone (including
                      us) rests with you.
                    </span>
                    <br />
                  </li>
                  <li>
                    <span>
                      IF YOU WANT TO ENSURE YOUR PRIVACY RIGHTS ARE NOT AFFECTED
                      IN ANY WAY, YOU SHOULD NOT TRANSACT ON BLOCKCHAINS AS
                      CERTAIN RIGHTS MAY NOT BE FULLY AVAILABLE OR EXERCISABLE
                      BY YOU OR US DUE TO THE TECHNOLOGICAL INFRASTRUCTURE OF
                      THE BLOCKCHAIN. IN PARTICULAR THE BLOCKCHAIN IS AVAILABLE
                      TO THE PUBLIC AND ANY PERSONAL DATA SHARED ON THE
                      BLOCKCHAIN WILL BECOME PUBLICLY AVAILABLE
                    </span>
                    <br />
                  </li>
                  <li>
                    <span></span>
                    <br />
                  </li>
                </ol>
              </li>
            </ol>

            <ol start="2">
              <li>
                <span>
                  <strong>2. What Information Do We Collect? </strong>
                </span>
                <ol>
                  <li>
                    <strong>2.1</strong>
                    <span> Information You Share With Us.</span>
                    <br />
                  </li>
                  <li>
                    <strong></strong>
                    <span>
                      {" "}
                      You may choose to give us information in various
                      situations such as filling out a form, participating in an
                      event or activity, raising support request, giving us
                      feedback, signing up for email alerts, newsletters, etc.
                      The type of information you may choose to give us
                      includes:
                    </span>
                    <br />
                    <li style={{ paddingTop: 12 + "px" }}>
                      <ol>
                        <li>
                          <span>
                            (a) Identity Information: This includes your name,
                            profile picture or other identifiers.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            (b) Contact Details: This includes your email and
                            phone number.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            (c) Personal Communications: This includes your
                            feedback, opinions, communication you send to us for
                            support request, etc.{" "}
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            (d) Marketing Communications: This includes your
                            preferences for receiving marketing communications
                            from us and how you interact with our marketing
                            content.{" "}
                          </span>
                        </li>
                        <br />
                      </ol>
                    </li>
                  </li>
                  <li>
                    <strong>2.2</strong>
                    <span> Information We Collect Automatically </span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      We try our best to limit the information we collect about
                      you automatically; however, we may collect certain types
                      of data when you interact with our Services or Site,
                      including but not limited to:{" "}
                    </span>
                  </li>
                  <li style={{ paddingTop: 12 + "px" }}>
                    <ol>
                      <li>
                        <span>
                          (a) <strong>Device Data: </strong>This includes data
                          such as browser type, device type, operating system,
                          etc.{" "}
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          (b) <strong>Blockchain Data:</strong> This includes
                          public blockchain data including blockchain addresses
                          and crypto asset transaction information.{" "}
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          (c) <strong>Aggregate Data:</strong> This includes
                          analytics about number of users and types and
                          information such as how many users in the aggregate
                          are using certain protocols with assistance of
                          Services; and location of users such as countries and
                          regions from which users access the Services or the
                          Site.
                        </span>
                      </li>
                      <br />
                    </ol>
                  </li>
                  <li>
                    <strong>2.3</strong>
                    <span> Information Collected via Third Parties</span>
                  </li>
                  <li>
                    <span>
                      We do not collect any information via third parties.{" "}
                    </span>
                  </li>
                </ol>
              </li>
            </ol>

            <ol start="3">
              <li>
                <span>
                  <strong>3. Do We Collect Any Child’s Data? </strong>
                </span>
              </li>
              <li>
                <strong></strong>
                <span>
                  {" "}
                  Our services are expressly for use for people above the age of
                  18 or majority in their jurisdiction.{" "}
                </span>
                <br />
              </li>
            </ol>

            <ol start="4">
              <li>
                <span>
                  <strong>4. How Do We Use That Information? </strong>
                </span>
                <li style={{ paddingTop: 12 + "px" }}>
                  <ol>
                    <li>
                      <span>
                        4.1 <strong>Quality Assurance Purposes: </strong> We may
                        use this data to personalise your use of the site or
                        service, improve on our services, provide support, and
                        resolve issues, etc.{" "}
                      </span>
                    </li>
                    <br />
                    <li>
                      <span>
                        4.2 <strong>Operations:</strong> We may use the data to
                        further supplement the operations of the site{" "}
                      </span>
                    </li>
                    <br />
                    <li>
                      <span>
                        4.3{" "}
                        <strong>
                          Prevent or Detect Violation of Policies and or Comply
                          with Law:{" "}
                        </strong>{" "}
                        We may use this data to analyse user behaviour to
                        prevent, detect or investigate any conduct that may be
                        in violation of our policies or in order to comply with
                        law, if we have a legal obligation to collect, use or
                        retain information about you or comply with legal
                        requests like subpoenas or requests from government
                        authorities.
                      </span>
                    </li>
                    <br />
                    <li>
                      <span>
                        4.3 <strong>Marketing: </strong> We may use this
                        information to analyse the effectiveness of marketing or
                        promotional strategies and market products, services, or
                        campaigns.{" "}
                      </span>
                    </li>
                    <br />
                  </ol>
                </li>
              </li>
            </ol>

            <ol start="5">
              <li>
                <span>
                  <strong>
                    5. Your Control on Your Data (Access and Deletion){" "}
                  </strong>
                </span>
                <li>
                  <span>
                    {" "}
                    If you need to update or delete certain data about you or
                    your interaction with the Services, you can contact us.{" "}
                  </span>
                  <br />
                </li>
                <li>
                  <span>
                    {" "}
                    You also have the choice of collection and use of
                    information. You can choose to not provide certain
                    information but that may affect your use of Services or
                    access to certain features of the Site.
                  </span>
                  <br />
                </li>
              </li>
            </ol>

            <ol start="6">
              <li>
                <span>
                  <strong>6. Location of Data </strong>
                </span>
                <li>
                  <span>
                    {" "}
                    CIFDAQ has affiliate companies located throughout the world.
                    Depending on the scope of your interactions with us,
                    information about you, including personal information, may
                    be stored in or accessed from multiple countries. Whenever
                    we transfer personal information to other jurisdictions, we
                    will ensure that the information is transferred in
                    accordance with this Privacy Policy and as permitted by
                    applicable data protection laws.
                  </span>
                  <br />
                </li>
              </li>
            </ol>

            <ol start="7">
              <li>
                <span>
                  <strong>7. How long Is your data retained? </strong>
                </span>
                <li>
                  <span>
                    {" "}
                    We retain any data collected by us under this Policy for as
                    long as it is necessary to fulfil the relevant purposes
                    described in this Privacy Policy, unless a longer retention
                    period is required or permitted by law such as for tax,
                    accounting, fraud prevention, investigative, and dispute
                    resolution purposes, or to establish or defend a dispute. In
                    some instances, we may anonymise personal information about
                    you such that it can no longer be used to identify you, in
                    which case we can use such information indefinitely without
                    further notice to you.{" "}
                  </span>
                  <br />
                </li>
              </li>
            </ol>

            <ol start="8">
              <li>
                <span>
                  <strong>8. Contact Us, Notices, and Revisions </strong>
                </span>
                <li>
                  <span>
                    {" "}
                    If you have any questions about this Privacy Policy, please
                    Contact Us at:
                    <a href="mailto:legal@cifdaq.com">legal@cifdaq.com</a>{" "}
                  </span>
                  <br />
                </li>
              </li>
            </ol>

            <ol start="9">
              <li>
                <span>
                  <strong>
                    9. Additional Information For Specific Jurisdictions{" "}
                  </strong>
                </span>
                <li style={{ paddingTop: 12 + "px" }}>
                  <ol>
                    <li>
                      <span>
                        <strong>9.1 </strong>If You Are a resident of EU, UK, or
                        Switzerland{" "}
                      </span>
                    </li>
                    <br />
                    <ol>
                      <li>
                        <span>
                          If you are a data subject in the European Economic
                          Area, the United Kingdom, or Switzerland, you have
                          certain rights with respect to your personal data
                          pursuant to the General Data Protection Regulation of
                          the European Union (“GDPR”) and similar laws. This
                          section applies to you.
                        </span>
                      </li>
                      <li>
                        <span>
                          Any reference to “personal information” in this
                          Privacy Policy is equivalent to “personal data” as
                          defined under GDPR. CIFDAQ is the controller of your
                          personal information as covered by this Privacy Policy
                          for purposes of GDPR. Our address is as follows:
                          Skelton Bay Lot, Fish Bay, Tortola, VG 1110, British
                          Virgin Island
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          We process your personal data in reliance on the legal
                          bases below. Where the purpose of processing is:
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          To provide our Services and Site and Improve Them: We
                          process your personal data as necessary to perform the
                          contract under which we provide our Services to you or
                          to allow you to access our Site.
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          To Comply With The Law and to prevent fraud, and
                          ensure compliance: We process your personal data as
                          necessary to comply with applicable laws and our legal
                          obligations. We have a legitimate interest in
                          processing your personal data, as described in this
                          Privacy Policy, and our reasons for doing so outweigh
                          any prejudice to your data protection rights. We also
                          process your personal data as necessary to comply with
                          our legal obligations.
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          For Marketing Communications; Or For Research And
                          Analytics: We have a legitimate interest in processing
                          your personal data, as described in this Privacy
                          Policy, and our reasons for doing so outweigh any
                          prejudice to your data protection rights.‍
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          With Your Consent: We process certain of your personal
                          data with your consent. You may withdraw your consent
                          at any time in the manner indicated when you provided
                          consent to the collection of your personal data
                          through your interactions with the Services or the
                          Site.
                        </span>
                      </li>
                      <br />
                    </ol>
                  </ol>
                  <ol>
                    <li>
                      <span>
                        <strong>Your rights:</strong>
                      </span>
                      <li>
                        <span>You may: </span>
                        <li style={{ paddingTop: 12 + "px" }}>
                          <ol>
                            <li>
                              <span>
                                <strong>(a) </strong> ask whether we have any
                                personal data about you and request a copy of
                                such personal data;
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(b) </strong> request that we update or
                                correct inaccuracies in your personal data;
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(c) </strong> request that we delete
                                your personal data;
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(d) </strong> request a portable copy of
                                your personal data;{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(e) </strong> request that we restrict
                                the processing of your personal data if such
                                processing is inappropriate; and{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(f) </strong> object to our processing
                                of your personal data. These rights are subject
                                to applicable law.
                              </span>
                            </li>
                            <br />
                          </ol>
                        </li>
                        <li>
                          <span>
                            If you would like to exercise any of these rights,
                            please contact us (see “Contact Us” section above).
                            To protect your information, we may need to verify
                            your identity before processing your request,
                            including by collecting additional information to
                            verify your identity, such as government issued
                            identification documents.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            If you would like to submit a complaint about our
                            use of your personal data or our response to your
                            requests regarding your personal data, please
                            Contact Us above. You may also lodge a complaint
                            with your local data protection authority.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            If we transfer your personal data outside the
                            European Economic Area, we will do so in accordance
                            with the terms of this Privacy Policy and applicable
                            data protection law.
                          </span>
                        </li>
                        <br />
                      </li>
                    </li>
                  </ol>
                  <ol>
                    <li>
                      <span>
                        <strong>9.1 </strong> If You Are a resident of
                        California
                      </span>
                    </li>
                    <br />
                    <ol>
                      <li>
                        <span>
                          If you are a California resident, you have certain
                          additional rights with respect to personal information
                          about you under the California Consumer Privacy Act of
                          2018 (“CCPA”).
                        </span>
                      </li>
                      <li>
                        <span>We are required to inform you of:</span>
                      </li>
                      <br />
                      <li>
                        <span>
                          What categories of information we may collect about
                          you, including during the preceding 12 months: See the
                          section “What information do we receive from you or
                          collect.”
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          The purposes for which we may use your personal
                          information and the purpose for which we share your
                          personal information: including during the preceding
                          12 months See the section “How we use information.”
                        </span>
                      </li>
                      <br />
                      <li>
                        <span>
                          We do not sell any personal information of consumers
                        </span>
                      </li>
                      <br />
                    </ol>
                  </ol>
                  <ol>
                    <li>
                      <span>
                        <strong>Your rights:</strong>
                      </span>
                      <li>
                        <span>You have the right to request to know: </span>
                        <li style={{ paddingTop: 12 + "px" }}>
                          <ol>
                            <li>
                              <span>
                                <strong>(a) </strong> the categories of personal
                                information we have collected about you in the
                                last 12 months;{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(b) </strong> the specific pieces of
                                personal information we have about you;{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(c) </strong> the categories of sources
                                from which that personal information was
                                collected;{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(d) </strong> the categories of your
                                personal information that we sold or disclosed
                                in the last 12 months, if applicable;{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(e) </strong> the categories of third
                                parties to whom your personal information was
                                sold or disclosed in the last 12 months; and{" "}
                              </span>
                            </li>
                            <br />
                            <li>
                              <span>
                                <strong>(f) </strong> the purpose for collecting
                                and selling your personal information, if
                                applicable. These rights are subject to
                                limitations as described in the relevant law. We
                                may deny your request if we need to do so to
                                comply with our legal rights or obligations.
                              </span>
                            </li>
                            <br />
                          </ol>
                        </li>
                        <li>
                          <span>
                            <strong>
                              We will not discriminate against any user for
                              exercising their CCPA rights.
                            </strong>{" "}
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            You may exercise these rights yourself or you may
                            designate an authorised agent to make these requests
                            on your behalf. To protect your information, we may
                            need to verify your identity before processing your
                            request, including by collecting additional
                            information to verify your identity, such as
                            government issued identification documents. We will
                            not fulfil your request unless you have provided
                            sufficient information for us to reasonably verify
                            you are the individual about whom we collected
                            personal information. We will only use the personal
                            information provided in the verification process to
                            verify your identity or authority to make a request
                            and to track and document request responses unless
                            you initially provided the information for another
                            purpose. When we verify your agent’s request, we may
                            verify your identity and request a signed document
                            from your agent that authorizes your agent to make
                            the request on your behalf. To protect your personal
                            information, we reserve the right to deny a request
                            from an agent who does not submit proof that they
                            have been authorised by you to act on their behalf.
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            If you would like to exercise any of these rights,
                            please contact us at{" "}
                            <a href="mailto:legal@cifdaq.com">
                              legal@cifdaq.com
                            </a>
                          </span>
                        </li>
                        <br />
                      </li>
                    </li>
                  </ol>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
