import React from "react";
import ContactUsComponent from "../components/ContactUsComponent";

const ContactUs = () => {

    return (
        <>
        
            <section className="search_block" style={{ height: 150 + "px" }}>
                <div className="main-container">
                    <div className="inner-row">
                        <h1>Contact Us</h1>
                    </div>
                </div>
                <div className="bg_globe">
                    <img src="/img/background_globe.svg" alt="" />
                </div>
            </section>

            <ContactUsComponent />
        </>
    )

}

export default ContactUs;