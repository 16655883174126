import axios from "axios";
import API from "./Api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const search = createAsyncThunk(
    'cifdscan/search',
    async (data, { rejectWithValue }) => {
        try {
            const response = await API.get(`cifdscan/search/${data}`);
            return response.data;
        } catch (error) {
            const errorMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                'Failed to search';
            return rejectWithValue(errorMessage);
        }
    },
)
