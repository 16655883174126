import axios from 'axios';
import API from './Api';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Action creator to fetch the latest blocks asynchronously
export const getLatestBlocks = createAsyncThunk(
    'blocks/getLatestBlocks',
    async (_, { rejectWithValue }) => { // Use underscore as we don't need any parameters here
        try {
            // Make the HTTP GET request to fetch the latest blocks
            const response = await API.get('cifdscan/latestBlockNumber');
            console.log(response, "response in services");
            return response.data; // Return the fetched data
        } catch (error) {
            // Handle errors here
            const errorMessage =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                'Failed to fetch latest blocks'; // Default error message
            // Return an error payload
            return rejectWithValue(errorMessage);
        }
    },
);
