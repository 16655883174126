import { configureStore } from '@reduxjs/toolkit';
import blockSlice from "./slices/block.slice";
import subscriberSlice from './slices/subscriber.slice';
import contactusSlice from './slices/contactus.slice';
import searchSlice from './slices/search.slice';

const reducer = {
    block: blockSlice,
    subscriber: subscriberSlice,
    contactUs: contactusSlice,
    search: searchSlice,
}

export const store = configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV !== 'production',
})