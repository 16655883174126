import React, { useRef } from "react";
import ScrollToTop from "react-scroll-to-top";
import NewsLetter from "./NewsLetter";

function FooterSection() {
    const currentYear = new Date().getFullYear();
    const ref = useRef(null);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    return (
        <>

            <section className="footer">
                <div className="container">
                    <footer className="inner-row">
                        <div className="row">
                            <div className="col-12 col-sm-12  col-xs-12 m-auto col-md-6">

                                <p> <img src="/img/brand_logo.svg" alt="" /> </p>
                                <p>
                                    CIFDAQ Scan is a block explorer and analytics platform for CIFDAQ, a <br></br> complete blockchain ecosystem, powered by AI.

                                </p>
                                <div className="social-icon">
                                    <ul>
                                     <li><a href="https://www.instagram.com/cifdaq/?igshid=YmMyMTA2M2Y%3D" target="_blank"><img src="/img/social-media/instagram.svg" alt="" /></a></li>
                                           <li><a href="https://twitter.com/cifdaq" target="_blank"> <svg
                        width="23"
                        height="17"
                        viewBox="0 0 32 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.2019 2H30.1087L19.3887 14.2523L32 30.925H22.1254L14.3913 20.8131L5.54174 30.925H0.631901L12.0981 17.8198L0 2H10.1252L17.1162 11.2427L25.2019 2ZM23.4797 27.988H26.1987L8.64785 4.78274H5.73013L23.4797 27.988Z"
                          fill="#000"
                        />
                      </svg></a></li>
                       <li><a href="https://t.me/cifdaqecosystem" target="_blank"><img src="/img/social-media/telegram.svg" alt="" /></a></li>
                                    <li><a href="https://discord.com/invite/ahfmnp8p9F" target="_blank"><img src="/img/social-media/discord.svg" alt="" /></a></li>
                                            
                                        <li><a href="https://www.linkedin.com/company/cifdaq/" target="_blank"><img src="/img/social-media/linkedin.svg" alt="" /></a></li>
                                       <li><a href="https://www.youtube.com/channel/UC7eMkjmnx_UCXULhLpQbSwg" target="_blank"><img src="/img/social-media/youtube.svg" alt="" /></a></li>
                                       
                                        <li><a href="https://www.facebook.com/cifdaq.cifd/" target="_blank"><img src="/img/social-media/facebook.svg" alt="" /></a></li>
                                        <li><a href="https://medium.com/@CIFDAQ" target="_blank"><img src="/img/social-media/medium.svg" alt="" /></a></li>
                                        
                                    
                                        
                                    </ul>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-xs-12 m-auto offset-1  col-md-6">
                                <NewsLetter />

                                <div className="row imp-links">
                                    <div className="col-6">
                                        <h5>Important Links</h5>
                                        <ul className="nav flex-column ">
                                            <li className="nav-item mb-2"><a href="https://cifdaq.io/" target="_blank" className="nav-link p-0 text-muted">About Us</a></li>
                                            <li className="nav-item mb-2"><a href="/contact-us" className="nav-link p-0 text-muted">Contact Us</a></li>

                                        </ul>
                                    </div>

                                    <div className="col-6">
                                        <h5>Others</h5>
                                        <ul className="nav flex-column">
                                            <li className="nav-item mb-2"><a href="/privacy-policy" className="nav-link p-0 text-muted">Privacy Policy</a></li>
                                            <li className="nav-item mb-2"><a href="/terms-conditions" className="nav-link p-0 text-muted">Terms & Conditions</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </footer>
                </div>
                <div >
                    <ScrollToTop smooth />
                </div >

            </section >

        </>
    )

}

export default FooterSection;