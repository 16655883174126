import React, { useEffect, useState } from "react";
import { Route, Link, Routes, useLocation, useParams } from 'react-router-dom';
import API from "../services/Api";
import SearchSeactionPage from "../components/SearchSeactionPage";
import Loader from '../components/LoaderComponent'
import web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { search } from "../services/search.service";
const { unixToTime, truncateHashString } = require("../helper/common");
const Address = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();


  const addVal = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length);

  // const [addData, setAddData] = useState([]);
  // const [errorData, setErrorData] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [convertVal, setConvertVal] = useState('0');
  const { error, isLoading, result: addData } = useSelector((state) => state.search.searchDetails);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(search(addVal));
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (addData && addData?.balance) {
      let ethValue = web3.utils.fromWei(addData?.balance, 'ether');
      console.log('ethValue', ethValue);
      setConvertVal(ethValue);
    }
  }, [addData]);

  console.log('Address', convertVal);
  return (
    <>
      <section className="search_block">
        <div className="main-container">
          <div className="inner-row">
            <h1>Address Details</h1>
            <SearchSeactionPage/>
          </div>
        </div>
        <div className="bg_globe">
          <img src="/img/background_globe.svg" alt="" />
        </div>
      </section>
      {isLoading &&
        <div style={{ minHeight: 500 + "px", padding: 10 + "%" }}>
          <Loader />
        </div>
      }
      {addData &&
        <div className="inner-row content_area">
          <div className="tabs">
            <section className="detail-block">
              <div className="main-container-1">
                <div className="inner-row-1">
                  <div className="grid-block">
                    <div className="tabel-block">
                      <table className="table md-tbl">
                        <tbody>
                          <tr>
                            <td className="wd">
                              Balance :
                            </td>
                            <td className="cstm_blck">
                              <span>{addData ? convertVal : 0} CIFD</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Transaction Count :
                            </td>
                            <td className="cstm_blck">
                              <span>{addData?.transactions}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Address :
                            </td>
                            <td className="cstm_blck">
                              <span>{addData?.address}</span>
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              Chain Id :
                            </td>
                            <td className="cstm_blck">
                              <span>{addData.result[0].chainId}</span>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      }

      {error != '' &&
        <div className="inner-row content_area" style={{ paddingTop: 100 + "px", paddingBottom: 100 + "px", fontSize: 15 + 'px', textAlign: "center" }}>
          <div className="tabs">
            <section className="detail-block">
              <div className="main-container-1">
                <div className="inner-row-1 ">
                  <h5>{error}</h5>
                </div>
              </div>
            </section>
          </div>
        </div>
      }
    </>
  )
}

export default Address;
