import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLatestBlocks } from '../services/block.service';

const blockSlice = createSlice({
    name: "block",
    initialState: {

        latestBlockDetails: {
            isLoading: false,
            status: '',
            latestBlockNumber: '',
            timestamp: '',
            value1: [],
            value2: [],
        }
    },
    reducers: {
        clearSuccessMessage: (state, payload) => {
            // TODO: Update state to clear success message
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getLatestBlocks.pending.type, (state, action) => {
            state.latestBlockDetails.status = 'pending';
            state.latestBlockDetails.isLoading = true;
        })

        builder.addCase(getLatestBlocks.fulfilled.type, (state, action) => {
            state.latestBlockDetails.status = 'success';
            state.latestBlockDetails.latestBlockNumber = action.payload.latestBlockNumber ? action.payload.latestBlockNumber : "";
            state.latestBlockDetails.timestamp = action.payload.timestamp ? action.payload.timestamp : "";
            state.latestBlockDetails.value1 = action.payload.recentBlocks ? action.payload.recentBlocks : "";
            state.latestBlockDetails.value2 = action.payload.transactions ? action.payload.transactions : "";
            state.latestBlockDetails.isLoading = false;
        })

        builder.addCase(getLatestBlocks.rejected.type, (state, action) => {
            state.latestBlockDetails.status = 'failed';
            state.latestBlockDetails.latestBlockNumber = "";
            state.latestBlockDetails.timestamp = "";
            state.latestBlockDetails.value1 = "";
            state.latestBlockDetails.value2 = "";
            state.latestBlockDetails.isLoading = true;
        })
    }
})

export default blockSlice.reducer;