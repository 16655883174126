import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Link, Routes, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import Loader from './LoaderComponent'
import { getLatestBlocks } from "../services/block.service";
import { faCube, faReceipt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import web3 from 'web3';


const { unixToTime, truncateHashString } = require("../helper/common");

function Datatable() {

      const [latestBlocks, setLatestBlockss] = useState([]);
    //const [extraBlockDetails, setExtraBlockDetails] = useState([]);
    const [insitialLoading, setInsitialLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLatestBlock, setIsLoadingLatestBlock] = useState(false);
    const [isLoadingLatestTransaction, setIsLoadingLatestTransaction] = useState(false);

    const latestBlock = useSelector((state) => state.block.latestBlockDetails.value1);
    //setLatestBlockss(latestBlocks);
    const extraBlockDetails = useSelector((state) => state.block.latestBlockDetails.value2);

    const dispatch = useDispatch();
    useEffect(() => {
        // setIsLoading(true);
        dispatch(getLatestBlocks());

    }, []);

    // setTimeout(() => {
    //     dispatch(getLatestBlocks());
    // }, 50000)
    // setIsLoading(false);
    //const data = useSelector(state => state.latestFirstBlockDetails.values);

    //setExtraBlockDetails(block2);

    //console.log(blockFirstDetails);
    /*   useEffect(() => {
  
          async function initialFetchData() {
              setInsitialLoading(true);
              setIsLoading(true);
              setIsLoadingLatestBlock(true);
              setIsLoadingLatestTransaction(true);
              try {
                  const response = await API.get('blocks/getLatestBlocks');
                  setLatestBlocks(response.data.result);
                  setExtraBlockDetails(response.data.extraDetails);
              } catch (error) {
                  console.log(error);
              } finally {
                  setIsLoading(false);
                  setInsitialLoading(false);
                  setIsLoadingLatestBlock(false);
                  setIsLoadingLatestTransaction(false);
              }
          }
          initialFetchData();
      }, []);
  
      useEffect(() => {
  
          const interval = setInterval(() => {
              async function fetchData() {
                  setIsLoading(true);
                  try {
                      const response = await API.get('blocks/getLatestBlocks');
                      setLatestBlocks(response.data.result);
                      setExtraBlockDetails(response.data.extraDetails);
                      setIsLoading(false);
                  } catch (error) {
                      console.log(error);
                  } finally {
                      setIsLoading(false);
                  }
              }
              fetchData();
          }, 30000);
  
      }, []); */

console.log("latestBlock CHECKING", latestBlock);
console.log("extraBlockDetails CHECKING", extraBlockDetails);
    return (
        <>

            {/* <!-- block fourth start --> */}
            <section className="fourth-block">
                {insitialLoading &&
                    <div style={{ minHeight: 500 + "px", padding: 10 + "%" }}>
                        <Loader />
                    </div>}
                {insitialLoading == false &&
                    <div className="main-container-1">
                        <div className="inner-row-1">
                            <div className="grid-block">
                                <div className="tabel-block">
                                    <p className="heading">Latest Blocks</p>
                                    {latestBlock.length > 0 &&
                                        latestBlock.map((item, index) => (
                                            (item !== undefined && item !== null &&
                                                <div className="flex_block" key={index}>
                                                    <div className="img_block">
                                                    <FontAwesomeIcon icon={faCube} />
                                                        {/* <img src="/img/box_blck.svg" alt="" /> */}
                                                    </div>
                                                    <div className="block_1">
                                                        <p><a href={`/block/${item.number}`}>{item.number}</a></p>
                                                        <span>{item.timestamp ? unixToTime(item.timestamp) : ""} ago</span>
                                                    </div>
                                                    <div className="block_2">
                                                        <p></p>
                                                        <span></span>
                                                    </div>
                                                    <div className="block_3">
                                                        <p data-tooltip-id={"block_" + index}>
                                                            {item.hash ? truncateHashString(item.hash) : ""}
                                                        </p>
                                                        <span>{item?.transactions?.length} txns </span>
                                                    </div>
                                                    <div className="block_4">
                                                        <p>{item.gasUsed ? item.gasUsed : "0.00"}</p>
                                                    </div>
                                                    <ReactTooltip
                                                        id={"block_" + index}
                                                        place="bottom"
                                                        content={item.hash}
                                                    />
                                                </div>

                                            )
                                        ))
                                    }
                                </div>
                                <div className="tabel-block transblock">
                                    <p className="heading">Latest Transactions</p>
                                    {extraBlockDetails.length > 0 &&
                                        extraBlockDetails.map((blockItem, index) => (
                                            (blockItem !== undefined && blockItem !== null &&
                                                <div className="flex_block" key={index} >
                                                    <div className="img_block">
                                                        {/* <img src="/img/box_blck.svg" alt="" /> */}
                                                        <FontAwesomeIcon icon={faReceipt} />
                                                    </div>
                                                    <div className="block_1">
                                                        <p><a href={`/tx/${blockItem.hash}`}>{truncateHashString(blockItem.hash)}</a></p>
                                                        <span>{blockItem.createdAt ? moment(blockItem.createdAt).fromNow() : ""}</span>
                                                    </div>
                                                    <div className="block_2">
                                                        <p></p>
                                                        <span></span>
                                                    </div>
                                                    <div className="block_3">
                                                        <p data-tooltip-id={"trans_" + index}>
                                                            {blockItem.from ? truncateHashString(blockItem.from) : ""}</p>
                                                        <span>to {truncateHashString(blockItem.to)}  </span>
                                                    </div>
                                                    <div className="block_4">
                                                        <p>{blockItem?.value ? web3.utils.fromWei(blockItem?.value) : "0.00"}</p>
                                                    </div>
                                                    <ReactTooltip
                                                        id={"trans_" + index}
                                                        place="bottom"
                                                        content={blockItem.hash}
                                                    />
                                                </div>
                                            )

                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </section>
            {/* <!-- block fourth end --> */}
        </>
    );
}

export default Datatable;
