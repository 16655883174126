import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Form, Button } from "react-bootstrap";
import API from "../services/Api";
import { addSubscriber } from "../services/subscriber.service";

function NewsLetter() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "", website: "cifdaqscan.io" },
  });

  const [isLoading, setIsLoading] = useState(false);
  const { subscriberDetails: subscribed } = useSelector(
    (state) => state.subscriber
  );
  const dispatch = useDispatch();

  const showSuccessAlert = useCallback(() => {
    if (!isLoading && subscribed?.subscribed?.subscribed === true) {
      Swal.fire({
        title: "Success",
        text: "Your email is subscribed successfully",
        icon: "success",
        confirmButtonColor: "#0f9d5c",
        confirmButtonText: "OK",
      });
    }
  }, [isLoading, subscribed?.success]);

  useEffect(() => {
    showSuccessAlert();
  }, [showSuccessAlert]);

  const showErrorAlert = useCallback(() => {
    if (!isLoading && subscribed?.subscribed?.subscribed === false) {
      Swal.fire({
        title: "Error",
        text: "Your email is already subscribed",
        icon: "error",
        confirmButtonColor: "#0f9d5c",
        confirmButtonText: "OK",
      });
    }
  }, [isLoading, subscribed?.error, subscribed?.message]);

  useEffect(() => {
    showErrorAlert();
  }, [showErrorAlert]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        await dispatch(addSubscriber(data));
        reset({ email: "", website: "cifdaqscan.io" });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, reset]
  );

  console.log("subscribed", subscribed?.subscribed);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h4>Get in touch</h4>
      <Form.Group className="mb-3">
        <div className="d-flex w-100 gap-2">
          <label className="visually-hidden">Email address</label>
          <div className="subscribe_bar">
            <div className="input-group">
              <Form.Control
                type="hidden"
                name="source"
                id="source"
                value="cifdsacn.io"
              />
              <Form.Control
                type="email"
                name="email"
                id="email"
                placeholder="Your email"
                disabled={isLoading}
                {...register("email", {
                  required: true,
                  pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                })}
              />
              {isLoading ? (
                <img
                  src="/img/loader/loader.gif"
                  style={{ margin: "auto", display: "block", alt: "Loading" }}
                />
              ) : (
                <span className="input-group-btn">
                  <button className="btn btn-default" type="submit">
                    Send
                  </button>
                </span>
              )}
            </div>
            {errors.email && errors.email.type === "required" && (
              <p className="errorMsg">Please enter your email.</p>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <p className="errorMsg">Email is not valid.</p>
            )}
          </div>
        </div>
      </Form.Group>
    </Form>
  );
}

export default NewsLetter;
