import React, { useState, useEffect } from 'react';
import API from "../services/Api";
import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../components/LoaderComponent'

const SearchSeaction = (props) => {

    const { resultData, updateResultData } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [allValid, setAllValid] = useState(true)
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    function naviGateTo(res, param) {
        if (res === 'address') {
            navigate(`/address/${param.address}`);
        } else if (res === 'transaction') {
            navigate(`/tx/${param.hash}`);
        } else if (res === 'block') {
            navigate(`/block/${param.number}`);
        }
    }

    const handleChange = event => {
        if (event.target.value === '') {
            setErrorMessage("Please enter wallet address or transaction hash to search");
        } else {
            setSearchKeyword(event.target.value);
        }

    };


    const getResult = async () => {
        try {
            setAllValid(false);
            setIsLoading(true);
            setErrorMessage('');
            if (searchKeyword.trim().length !== 0) {
                const response = await API.get(`cifdscan/search/${searchKeyword}`);
                console.log(response, "search response");
                props.updateResultData(response?.data?.result);
                naviGateTo(response?.data?.type, response?.data?.result);
                setSearchKeyword('');
            } else {
                setSearchKeyword('');
                setErrorMessage("Please enter valid wallet address, transaction hash or block number to search");
                setAllValid(true);
                setIsLoading(false);
            }

        } catch (err) {
            setSearchKeyword('');
            setErrorMessage("Please enter valid wallet address, transaction hash or block number to search");
            setAllValid(true);
            setIsLoading(false);
        }

    };


    const handleKeyDown = event => {

        if (event.key === 'Enter') {
            getResult();
        } else {
            setErrorMessage('');
        }

    };

    return (
        <>
            <section className="search_block">
                <div className="main-container">
                    <div className="inner-row">
                        <h1>The <span className="cifd_block">CIFDAQ Blockchain</span> Explorer</h1>
                        <div className="search_data">
                            <div className="search_bar">
                                <input id="keywords"
                                    name="keywords"
                                    type="text"
                                    disabled={!allValid}
                                    onChange={e => setSearchKeyword(e.target.value)}
                                    value={searchKeyword}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Search by Address / Txn Hash / Block" />
                                {isLoading &&
                                    <img src="/img/loader/loader.gif" style={{ margin: 'auto', display: 'block', alt: 'Loading' }} />
                                }{
                                    isLoading == false &&
                                    <img onClick={getResult} src="img/search-icon.svg" alt="" />
                                    
                                    
                                }
                                {errorMessage && <div className="error"> {errorMessage} </div>}
                            </div>
                        </div>

                        {<div className="list_type">
                            <p>Search examples : </p>
                            <ul>
                                <li><a style={{ cursor: "default" }}><img src="/img/search_mailbox.svg" alt="" />Address</a></li>
                                <li><a style={{ cursor: "default" }}><img src="/img/search_block.svg" alt="" />Block</a></li>
                                <li><a style={{ cursor: "default" }}><img src="/img/search_transaction_hash.svg" alt="" />Transaction Hash </a></li>
                            </ul>
                        </div>}

                    </div>

                </div>
                <div className="bg_globe">
                    {/* <img src="/img/background_globe.svg" alt="" /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
  <path d="M10.3737 17.9175C14.5405 17.9175 17.9183 14.5397 17.9183 10.3729C17.9183 6.20609 14.5405 2.82825 10.3737 2.82825C6.20694 2.82825 2.8291 6.20609 2.8291 10.3729C2.8291 14.5397 6.20694 17.9175 10.3737 17.9175Z" stroke="black" stroke-width="1.88616" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19.8059 19.805L15.7035 15.7026" stroke="black" stroke-width="1.88616" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>



            </section>


        </>
    )

}

export default SearchSeaction;