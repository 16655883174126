import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { search } from '../services/search.service';


const searchSlice = createSlice({
    name:"search",
    initialState: {
        searchDetails: {
            isLoading: false,
            success: '',
            result: {},
            type: '',
            error: ''
        }
    },
    reducers: {
        clearSuccessMessage: (state, payload) => {

        }
    },
    extraReducers: (builder) => {
        builder.addCase(search.pending.type, (state, action) => {
            state.searchDetails.isLoading = true;
        })

        builder.addCase(search.fulfilled.type, (state, action) => {
            state.searchDetails.isLoading = false;
            state.searchDetails.success = true;
            state.searchDetails.result = action.payload.result;
            state.searchDetails.type = action.payload.type;
        })

        builder.addCase(search.rejected.type, (state, action) => {
            state.searchDetails.isLoading = false;
            state.searchDetails.success = false;
            state.searchDetails.error = action.payload;
        })
    }
})

export default searchSlice.reducer;
