import React from "react";

const Nft = (props) => {

  return (
    <>
      <div  style={{ minHeight: 250 + 'px' }}>
        <p className="coming_soon">Coming Soon.</p>
      </div>
    </>
  );
}

export default Nft;
