import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addContactUs } from '../services/contactus.service';

const contactUsSlice = createSlice({
    name: "contactUs",
    initialState: {
        contactUsDetails: {
            isLoading: false,
            status: '',
            message: ''
        }
    },
    reducers: {
        clearSuccessMessage: (state, payload) => {

        }
    },
    extraReducers: (builder) => {
        builder.addCase(addContactUs.pending.type, (state, action) => {
            state.contactUsDetails.status = 'pending';
            state.contactUsDetails.isLoading = true;
        })

        builder.addCase(addContactUs.fulfilled.type, (state, action) => {
            state.contactUsDetails.status = 'success';
            state.contactUsDetails.message = action.payload.message ? action.payload.message : "";
            state.contactUsDetails.isLoading = false;
        })

        builder.addCase(addContactUs.rejected.type, (state, action) => {
            state.contactUsDetails.status = 'failed';
            state.contactUsDetails.message = "";
            state.contactUsDetails.isLoading = false;
        })
    }
})

export default contactUsSlice.reducer;
