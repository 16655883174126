import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import API from "../services/Api";
import { websiteName } from "../helper/common";
import { addContactUs } from "../services/contactus.service";
import { Helmet } from "react-helmet";

function ContactUsComponent() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      mobile: "",
      email: "",
      message: "",
      source: "CIFDAQ Blockchain Explorer",
    },
  });

  /*  useEffect(() => {
     const name = websiteName();
     document.title = "Contact Us | " + name;
     document.meta = "Contact Us description";
   }, []); */

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Sucessfully sent",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const showErrorAlert = () => {
    Swal.fire({
      title: "Error",
      text: "Something went wrong. Plase try again later",
      icon: "error",
      confirmButtonText: "OK",
    });
  };

  const onSubmit = async (data) => {
    const realData = {
      name: data.fullName,
      phoneNumber: data.mobile,
      email: data.email,
      comments: data.message,
      workingRequest: " my working request",
      website: "cifdaqscan.io",
    };
    try {
      setIsLoading(true);
      setTimeout(() => {
        dispatch(addContactUs(realData))
          .unwrap()
          .then((response) => {
            if (response.success === true) {
              showSuccessAlert();
              reset({
                fullName: "",
                mobile: "",
                email: "",
                message: "",
                source: "CIFDAQ Blockchain Explorer",
              });
              setIsLoading(false);
            } else {
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }, 1000);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showErrorAlert();
    }
  };
  /* 
    useEffect(() => {
      if (formState.isSubmitSuccessful) {
        reset({ fullName: "" })
      }
    }, [formState, submittedData, reset]) */

  /*  const postFormData = useCallback(async () => {
     try {
       const response = await API.post('email/', {});
       if (response.status == true) {
         reset();
       }
     } catch (err) {
       console.error(err)
     }
   }, [reset]);
 
   useEffect(() => {
     if (formState.isSubmitSuccessful) {
       reset({ something: '' });
     }
   }, [formState, submittedData, reset]); */

  return (
    <>
      <Helmet>
        <title>Contact Us | Cifdaq Scan</title>
        <meta
          name="description"
          content="Get in touch with us at Cifdaq scan for any queries, assistance, or information related to CIFDAQ Blockchain Explorer, We're here to help you explore the blockchain ecosystem"
        />
        <link rel="canonical" href="https://cifdaqscan.io/contact" />
        <meta name="author" content="cifdaqscan.io" />
        <meta
          name="keywords"
          content="Cifdaq scan contact us, CIFDAQ Blockchain cifd, explorer, transaction hash, search, blockchain, crypto, currency"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta property="og:title" content="CIFDAQ Blockchain Explorer" />
        <meta
          property="og:description"
          content="Get in touch with us at Cifdaq scan for any queries, assistance, or information related to CIFDAQ Blockchain Explorer, We're here to help you explore the blockchain ecosystem"
        />
        <meta property="og:url" content="http://cifdaqscan.io/" />
        <meta name="twitter:title" content=" CIFDAQ Blockchain Explorer" />
        <meta
          property="twitter:description"
          content="Get in touch with us at Cifdaq scan for any queries, assistance, or information related to CIFDAQ Blockchain Explorer, We're here to help you explore the blockchain ecosystem"
        />
      </Helmet>
      <div className="vh-10 d-flex justify-content-center align-items-center contactus">
        <Col md={7} lg={7} xs={10} className="form_block">
          <Card className="px-4">
            <Card.Body>
              <div className="mb-2 mt-md-4">
                <div className="mb-3">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="Name">
                      <Form.Control
                        type="text"
                        name="fullName"
                        placeholder="Name"
                        {...register("fullName", {
                          required: true,
                          pattern: /^[a-zA-Z\s]*$/,
                        })}
                      />
                      {errors.fullName &&
                        errors.fullName.type === "required" && (
                          <p className="errorMsg">Please enter your name.</p>
                        )}
                      {errors.fullName &&
                        errors.fullName.type === "pattern" && (
                          <p className="errorMsg">Invalid input.</p>
                        )}
                      <Form.Control
                        type="hidden"
                        name="source"
                        id="source"
                        value="CIFDAQ Blockchain Explorer"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="mobile">
                      <Form.Control
                        min="0"
                        type="number"
                        step="1"
                        name="mobile"
                        placeholder="Mobile Number"
                        {...register("mobile", {
                          required: true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                          minLength: 10,
                          maxLength: 10,
                        })}
                      />
                      {errors.mobile && errors.mobile.type === "required" && (
                        <p className="errorMsg">Plese enter your mobile no.</p>
                      )}
                      {errors.mobile && errors.mobile.type === "pattern" && (
                        <p className="errorMsg">Invalid input.</p>
                      )}
                      {errors.mobile && errors.mobile.type === "minLength" && (
                        <p className="errorMsg">
                          Mobile no should be at-least 10 digit.
                        </p>
                      )}
                      {errors.mobile && errors.mobile.type === "maxLength" && (
                        <p className="errorMsg">
                          Mobile no should be at-least 10 digit.
                        </p>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <p className="errorMsg">Please enter your email.</p>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <p className="errorMsg">Email is not valid.</p>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="message">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="3"
                        placeholder="Message"
                        {...register("message", {
                          required: true,
                        })}
                      />
                      {errors.message && errors.message.type === "required" && (
                        <p className="errorMsg">Please enter your message.</p>
                      )}
                    </Form.Group>
                    {isLoading && (
                      <img
                        src="/img/loader/loader.gif"
                        style={{
                          width: 25 + "px",
                          margin: "auto",
                          display: "block",
                          alt: "Loading",
                        }}
                      />
                    )}
                    {isLoading == false && (
                      <div className="d-grid mb-9">
                        <Button
                          className="mb-3 "
                          variant="success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}

export default ContactUsComponent;
