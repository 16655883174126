import React from "react";
import { useLocation } from 'react-router-dom';



function NavBar() {
  const location = useLocation();
  let currentPath = location.pathname;
  return (
    <>
      <section className="sticky_header">
        <nav className="navbar navbar-expand-lg" aria-label="">
          <div className="container-nav">

            <a className="navbar-brand" href={currentPath && currentPath == '/' ? '#' : '/'}>
              <img src="/img/logo.svg" alt="block-explorer" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mainMenu"
              aria-controls="mainMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="nav_wraper">
              <div
                className="collapse navbar-collapse justify-content-md-end"
                id="mainMenu"
              >
                <ul className="navbar-nav mxs mb-2 mb-lg-0 adj-nav">
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://cifdaqwallet.com">
                      Wallet
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://linktr.ee/cifdaq">
                      Linktree
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

      </section >
    </>
  );
}

export default NavBar;
