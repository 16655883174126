import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Tx from '../pages/tx';
import Address from '../pages/address';
import Account from '../pages/account';
import Block from '../pages/block';
import Validator from '../pages/validator';
import Token from '../pages/token';
import Nft from '../pages/nft';
import TermsConditions from '../pages/termsConditions';
import PrivacyPolicy from '../pages/privacyPolicy';
import Home from "../pages/home";
import NavBar from '../components/NavBar';
import SearchSeaction from "../components/SearchSeaction";
import FooterSection from "../components/Footer";
import ContactUs from "../pages/contactUs";
import NotFound from "../components/NotFound";

const AppRouter = () => {
  const [resultData, setResultData] = useState({});

  const updateResultData = (data) => {
    setResultData((prevResultData) => ({ ...prevResultData, ...data }));
  };

  const resetResultData = () => {
    setResultData({});
  };

  return (
    <BrowserRouter>
      <HelmetProvider>
        <NavBar />
        <div className="Content">
          <Routes>
            <Route path="/" element={<Home resultData={resultData} updateResultData={updateResultData} />} />
            <Route element={<SearchSeaction resultData={resultData} updateResultData={updateResultData} />} path="/" exact="true" />
            <Route element={<Tx resultData={resultData} updateResultData={updateResultData} />} path="/tx/:id" exact="true" />
            <Route element={<Address resultData={resultData} updateResultData={updateResultData} />} path="/address/:id" exact="true" />
            <Route element={<Block resultData={resultData} updateResultData={updateResultData} />} path="/block/:id" exact="true" />
            <Route element={<Account resultData={resultData} updateResultData={updateResultData} />} path="/account" exact="true" />
            <Route element={<Token resultData={resultData} updateResultData={updateResultData} />} path="/token" exact="true" />
            <Route element={<Nft resultData={resultData} updateResultData={updateResultData} />} path="/nft" exact="true" />
            <Route element={<Validator resultData={resultData} updateResultData={updateResultData} />} path="/validator" exact="true" />
            <Route element={<PrivacyPolicy />} path="/privacy-policy" exact="true" />
            <Route element={<ContactUs />} path="/contact-us" exact="true" />
            <Route element={<TermsConditions />} path="/terms-conditions" exact="true" />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
        <FooterSection />
      </HelmetProvider>
    </BrowserRouter>

  );
};

export default AppRouter;
