import axios from "axios";
import API from "./Api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addContactUs = createAsyncThunk(
  "email/contactmail",
  async (data) => {
    try {
      const response = await API.post("/contact-us", data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return message;
    }
  }
);
