import React, { useEffect, useState } from "react";
import { Route, Link, Routes, useLocation, useParams } from 'react-router-dom';
import API from "../services/Api";
import Loader from '../components/LoaderComponent'
import SearchSeactionPage from "../components/SearchSeactionPage";
import { useDispatch, useSelector } from "react-redux";
import { search } from "../services/search.service";
const { unixToTime, truncateHashString } = require("../helper/common");


const Block = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();


  
  const addVal = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length);

  const {error, isLoading, result:addData, success } = useSelector((state) => state.search.searchDetails);

console.log(addVal, "addVal");

    

  useEffect(() => {
    async function fetchData() {
      try {
        // const response = await API.get(`cifdscan/search${addVal}`);
        dispatch(search(addVal))

      } catch (error) {
        console.error(error);
        
      } finally {


      }
    }
    fetchData();
  }, []);

console.log(addData, "addData");

  return (
    <>
      <section className="search_block">
        <div className="main-container">
          <div className="inner-row">
            <h1>Block Details</h1>
            <SearchSeactionPage
    
            />
          </div>
        </div>
        <div className="bg_globe">
          <img src="/img/background_globe.svg" alt="" />
        </div>
      </section>
      {isLoading &&
        <div style={{ minHeight: 500 + "px", padding: 10 + "%" }}>
          <Loader />
        </div>
      }
      {addData &&
        <div>
          <div className="inner-row content_area">
            <div className="tabs">
              <section className="detail-block">
                <div className="main-container-1">
                  <div className="inner-row-1">
                    <div className="grid-block">
                      <div className="tabel-block">
                        <table className="table md-tbl">
                          <tbody>
                            <tr>
                              <td className="wd">
                                Block Number :
                              </td>
                              <td className="cstm_blck">
                                <span>{addData.number}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Hash :
                              </td>
                              <td className="cstm_blck">
                                <span>{addData.hash}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Parent Hash :
                              </td>
                              <td className="cstm_blck">
                                <span>{addData.parentHash}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Timestamp :
                              </td>
                              <td className="cstm_blck">
                                <span>  {unixToTime(addData.timestamp)+ ` ago`}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Block Size :
                              </td>
                              <td className="cstm_blck">
                                <span>  {addData.size}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Transaction Count:
                              </td>
                              <td className="cstm_blck">
                                {addData?.transactions?.length > 0 ? 
                                  <span>{addData?.transactions?.length}</span>
                                  :
                                  <span>0</span>
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>
                               gas Limit :
                              </td>
                              <td className="cstm_blck">
                                <span>{addData.gasLimit}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                gas Used :
                              </td>
                              <td className="cstm_blck">
                                <span>{addData.gasUsed}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      }

      {error != '' &&
        <div className="inner-row content_area" style={{ paddingTop: 100 + "px", paddingBottom: 100 + "px", fontSize: 15 + 'px', textAlign: "center" }}>
          <div className="tabs">
            <section className="detail-block">
              <div className="main-container-1">
                <div className="inner-row-1 ">
                  <h5>{error}</h5>
                </div>
              </div>
            </section>
          </div>
        </div>
      }
    </>
  )
}

export default Block;
