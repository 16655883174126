import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import SearchSeactionPage from "../components/SearchSeactionPage";
import API from "../services/Api";
import Loader from '../components/LoaderComponent';
import web3 from "web3";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { search } from "../services/search.service";
const { unixToTime, truncateHashString } = require("../helper/common");

const Tx = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const addVal = location.pathname.slice(location.pathname.lastIndexOf("/") + 1,  location.pathname.length);

  const [convertVal, setConvertVal] = useState({});

  const { error, isLoading, result: addData } = useSelector((state) => state.search.searchDetails);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(search(addVal));
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (addData && addData?.value && addData?.gas && addData?.gasPrice) {
      const ethValue = web3.utils.fromWei(String(addData?.value), 'ether');
      const gasPrice = addData?.gasPrice?.toString();
      const letConvertVal = gasPrice / (10 ** 9);
      const transactionFee = addData?.gas?.toString() * web3.utils.fromWei(gasPrice);
      setConvertVal({ value: ethValue?.toString(), gasPrice: letConvertVal?.toString(), transactionFee: transactionFee?.toString() });
    }
  }, [addData]);

  console.log('resultData in tx', addData);
  console.log('convertVal in tx', convertVal);

  return (
    <>
      <section className="search_block">
        <div className="main-container">
          <div className="inner-row">
            <h1>Transaction Details</h1>
            <SearchSeactionPage />
          </div>
        </div>
        <div className="bg_globe">
          <img src="/img/background_globe.svg" alt="" />
        </div>
      </section>
      {isLoading &&
        <div style={{ minHeight: 500 + "px", padding: 10 + "%" }}>
          <Loader />
        </div>
      }
      {addData &&
        <div className="inner-row content_area">
          <div className="tabs">
            <section className="detail-block">
              <div className="main-container-1">
                <div className="inner-row-1">
                  <div className="grid-block">
                    <div className="tabel-block">
                      <table className="table md-tbl">
                        <tbody>
                          <tr>
                            <td className="wd">Transaction Hash :</td>
                            <td className="cstm_blck"><span>{addData.hash}</span></td>
                          </tr>
                          <tr>
                            <td className="wd">Status :</td>
                            <td className="cstm_blck"><span>Success</span></td>
                          </tr>
                          <tr>
                            <td>Block Hash :</td>
                            <td className="cstm_blck"><span>{addData.blockHash}</span></td>
                          </tr>
                          <tr>
                            <td>Block Number :</td>
                            <td className="cstm_blck"><span>{addData.blockNumber}</span></td>
                          </tr>
                          <tr>
                            <td>Time Stamp :</td>
                            <td className="cstm_blck"><span>{`${moment.unix(addData.timestamp).utc().format('Do MMM YYYY')} at ${moment.unix(addData.timestamp).utc().format('HH:mm:ss')}`} UTC</span></td>
                          </tr>
                          <tr>
                            <td>From :</td>
                            <td className="cstm_blck"><span>{addData.from}</span></td>
                          </tr>
                          <tr>
                            <td>To :</td>
                            <td className="cstm_blck"><span>{addData.to}</span></td>
                          </tr>
                          <tr>
                            <td>Value :</td>
                            <td className="cstm_blck"><span>{addData ? convertVal.value : 0} CIFD</span></td>
                          </tr>
                          <tr>
                            <td>Gas limit:</td>
                            <td className="cstm_blck"><span>{addData.gas}</span></td>
                          </tr>
                          <tr>
                            <td>Gas Price :</td>
                            <td className="cstm_blck"><span>{addData ? convertVal.gasPrice : 0} Gwei</span></td>
                          </tr>
                          <tr>
                            <td>Transaction Fee:</td>
                            <td className="cstm_blck"><span>{addData ? convertVal.transactionFee : 0} CIFD</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      }
      {error !== '' &&
        <div className="inner-row content_area" style={{ paddingTop: 100 + "px", paddingBottom: 100 + "px", fontSize: 15 + 'px', textAlign: "center" }}>
          <div className="tabs">
            <section className="detail-block">
              <div className="main-container-1">
                <div className="inner-row-1 ">
                  <h5>{error}</h5>
                </div>
              </div>
            </section>
          </div>
        </div>
      }
    </>
  );
}

export default Tx;
