import React from "react";
import SearchSeaction from "../components/SearchSeaction";
import BlockItem from "../components/BlockItem";
import LatestBlocks from "../components/LatestBlocks";
import { Helmet } from "react-helmet";
const Home = (props) => {
  const { resultData, updateResultData } = props;
  return (
    <>
      <Helmet>
        <title>Discover CIFDAQ Blockchain with CIFDAQ Scan - Track Transactions, Addresses, Tokens & Prices</title>
        <meta name="description" content="Navigate the CIFD blockchain effortlessly using Cifdaqscan, explore transactions, addresses, tokens, and real-time prices for an in-depth understanding of the CIFDAQ ecosystem" />
        <link rel="canonical" href="https://cifdaqscan.io/" />
        <meta name="author" content="cifdaqscan.io" />
        <meta name="keywords" content="Cifdaq Scan, transaction hash, cifd, Cifdaq Blockchain, blockchain, crypto, explorer, currency,  blockchain ecosystem, block explorer, search" />
        <meta name="format-detection" content="telephone=no" />
        <meta property="og:title" content="Discover CIFDAQ Blockchain with CIFDAQ Scan - Track Transactions, Addresses, Tokens & Prices" />
        <meta property="og:description" content="Navigate the CIFD blockchain effortlessly using Cifdaqscan, explore transactions, addresses, tokens, and real-time prices for an in-depth understanding of the CIFDAQ ecosystem" />
        <meta property="og:url" content="http://cifdaqscan.io/" />
        <meta name="twitter:title" content="Discover CIFDAQ Blockchain with CIFDAQ Scan - Track Transactions, Addresses, Tokens & Prices" />
        <meta property="twitter:description" content="Navigate the CIFD blockchain effortlessly using Cifdaqscan, explore transactions, addresses, tokens, and real-time prices for an in-depth understanding of the CIFDAQ ecosystem" />
      </Helmet>
      <SearchSeaction
        resultData={resultData}
        updateResultData={updateResultData}
      />

      <section className="second-block">
        <div className="main-container">
          <div className="inner-row">
            <div className="stastics_block">
              <BlockItem />
            </div>
            <LatestBlocks />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
