import React from "react";

const TermsConditions = (props) => {
  return (
    <>
      <section className="search_block" style={{ height: 140 + "px" }}>
        <div className="main-container">
          <div className="inner-row">
            <h1>Terms & Conditions</h1>
          </div>
        </div>
        <div className="bg_globe">
          <img src="/newimg/background_globe.svg" alt="" />
        </div>
      </section>
      <div className="main-container">
        <div className="inner-row content_area mt-5 privacyPolicy">
          <div style={{ minHeight: 500 + "px" }}>
            <p>
              <span>
                THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION. IF YOU ARE
                ACCESSING OUR SERVICES AND WE CANNOT RESOLVE A DISPUTE THROUGH
                OUR USER OPERATIONS TEAM, THE DISPUTE MUST BE SETTLED IN BINDING
                ARBITRATION AS SET FORTH IN THIS AGREEMENT. BY ENTERING INTO
                THIS AGREEMENT, YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY
                AND ABILITY TO PARTICIPATE IN A CLASS ACTION.
              </span>
            </p>

            <p>IF YOU DO NOT AGREE, PLEASE DO NOT USE THE SERVICES OR SITE.</p>

            <p className="ml-2 text-center">
              <br />
              <br />
              <span>
                <strong>Terms of Use</strong>
              </span>
            </p>

            <br />
            <p>&nbsp;</p>

            <p>
              {" "}
              <span>
                These terms of use, together with any documents and additional
                terms or policies that expressly incorporate these Terms of Use
                by reference, including the Privacy Policies (these &ldquo;
                <strong>Terms of Use</strong>&rdquo;), govern your access to and
                use of all products, website (including any sub domains),
                content, functionality, features and services (the &ldquo;
                <strong>Services</strong>&rdquo;) available on or through,
                exclusively or non-exclusively, on
                https://cifdaqscan.io/,&nbsp;and any other website, web
                application or mobile application operated by us (collectively,
                the &ldquo;<strong>Site</strong>&rdquo;), and is a binding
                agreement between us (together with its affiliates, (&ldquo;
                <strong>CIFDAQ Wallet</strong>&rdquo; or &ldquo;
                <strong>We</strong>: and its derivatives) and You (and its
                derivatives).
              </span>
            </p>

            <p>
              <span>
                The term <strong>&ldquo;You&rdquo; </strong>refers to you
                individually or the legal entity on whose behalf the services
                are used, as applicable.{" "}
              </span>
            </p>

            <p>
              <span>
                By browsing and/using the site and or services, you represent
                that:{" "}
              </span>
            </p>

            <p>
              <span>
                (1) You have read, understood, and agree to be bound by the
                terms,{" "}
              </span>
            </p>

            <p>
              <span>
                (2) You are of legal age and meet the eligibility criteria as
                set out below, and{" "}
              </span>
            </p>

            <p>
              <span>
                (3) You have the authority to enter into the terms personally or
                on behalf of the legal entity for whom you are using the
                services.{" "}
              </span>
            </p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>
            <p>
              <span>
                <strong>
                  IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT
                  ACCESS OR USE THE SERVICES
                </strong>
                .
              </span>
            </p>

            <p>&nbsp;</p>

            <p className="ml-1 text-justify">
              <span>
                We may modify these Terms at any time and in our sole
                discretion. The modified terms will become effective upon
                posting. By continuing to use the Site after the effective date
                of any modifications to these Terms, you agree to be bound by
                the modified terms. It is your responsibility to check the Site
                regularly for modifications to these Terms.
              </span>
            </p>

            <p className="ml-1 text-justify">
              <span>
                For information regarding our collection, use and disclosure of
                personal data and certain other data, please see our Privacy
                Policies on the App and on the Website (the &ldquo;
                <strong>Privacy Policies</strong>&rdquo;). By using the Site,
                you consent to our collection, use and disclosure of personal
                data and other data as outlined in the Privacy Policy.
              </span>
            </p>

            <p className="ml-1 text-justify">
              <span>
                When using certain Services or Products, you may be subject to
                additional terms applicable to such Services that may be posted
                on or within the Product or Services or the Site associated with
                such Services. By Continuing to use the Product or Site or
                Services following the release of updated Terms you consent to
                the updated Terms. We invite you to check this page regularly
                for updates to these Terms.
              </span>
            </p>

            <ol start="1">
              <li>
                <span>
                  <strong>1. Use Of The Services</strong>
                </span>

                <ol>
                  <li>
                    <strong>1.1</strong>{" "}
                    <span>
                      The Site, the Products, the Services, and the information
                      and content available therein (
                      <strong>&ldquo;CIFDAQ Content&rdquo;</strong>) are
                      protected worldwide by copyright laws. Subject to the
                      Terms, CIFDAQ grants you a limited license to reproduce
                      portions of CIFDAQ Content solely as required to use the
                      Services for your personal or internal business purposes.
                      Unless otherwise specified by CIFDAQ in a separate
                      license, your right to use any CIFDAQ Content is subject
                      to these Terms.{" "}
                    </span>
                  </li>
                </ol>
              </li>
            </ol>

            <ol start="2">
              <li>
                <span>
                  <strong>2. Authorised Users</strong>
                </span>

                <ol>
                  <li>
                    <strong>2.1</strong>{" "}
                    <span>
                      {" "}
                      The Services, Product and the Site are intended for users
                      who are 18 years of age or older and belong to a
                      jurisdiction where the use of the Site and Services is
                      legal. If you are entering into these Terms of Use for an
                      entity, such as the company you work for, you represent to
                      us that you have the legal authority to bind such an
                      entity. If you do not meet these requirements, you must
                      not access or use the Products, Services, or the Site.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <ol start="3">
              <li>
                <span>
                  <strong>3. Ownership.</strong>
                </span>
                <ol>
                  <li>
                    <strong>3.1</strong>
                    <span>
                      {" "}
                      CIFDAQ and its suppliers own all right, title, and
                      interest in and to the CIFDAQ content contained within the
                      Site, Product and Services. You will not remove, alter, or
                      obscure any copyright, trademark, service mark or other
                      proprietary rights notices incorporated in or accompanying
                      the CIFDAQ Content.
                    </span>
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>3.2</strong>
                    <span>
                      {" "}
                      CIFDAQ and other related graphics, logos, service marks
                      and trade names used on or in connection with the Product,
                      Site or Services are the trademarks of CIFDAQ and may not
                      be used without permission in connection with any
                      third-party products or services. Other trademarks,
                      service marks, and trade names that may appear on or in
                      the Site or Services are the property of their respective
                      owners.
                    </span>
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>3.3</strong>
                    <span>
                      {" "}
                      You own your User Content. By posting, displaying,
                      sharing, or distributing User Content on or through the
                      Product, Site, or Services, you grant us, and any
                      Third-Party Provider used in connection with the Services,
                      a nonexclusive license to use the User Content solely for
                      the purpose of operating the Services. Except as
                      prohibited by applicable law, we may disclose any
                      information in our possession (including User Content) in
                      connection with your use of the Services, to:
                    </span>
                    <p className="ml-54 text-justify">&nbsp;</p>
                    <ol>
                      <li style={{ listStyleType: "none" }}>
                        <ol style={{ listStyleType: "lower-alpha" }}>
                          <li>
                            <span>(a) comply with legal process;</span>
                          </li>
                          <br />
                          <li>
                            <span>(b) enforce these Terms,</span>
                          </li>
                          <br />
                          <li>
                            <span>
                              (c) respond to your requests for customer service,
                              or
                            </span>
                          </li>
                          <br />
                          <li>
                            <span>
                              (d) protect the rights, property, or personal
                              safety of CIFDAQ, our employees, directors or
                              officers, partners and agents, or members of the
                              public.
                            </span>
                          </li>
                          <br />
                        </ol>
                      </li>
                    </ol>
                    &nbsp;
                  </li>
                  <li>
                    <strong>3.4</strong>
                    <span>
                      {" "}
                      You may provide ideas, suggestions, documents, and/or
                      proposals about the Services to CIFDAQ through any means (
                      <strong>&ldquo;Feedback&rdquo;</strong>), and you grant
                      CIFDAQ a fully paid, royalty-free, perpetual, irrevocable,
                      worldwide, non-exclusive, and sublicensable right and
                      license to use Feedback for any legitimate purpose.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
            <ol start="4">
              <li>
                <span>
                  <strong>4. User Conduct</strong>
                </span>
                <ol>
                  <li>
                    <strong>4.1</strong>
                    <span>
                      {" "}
                      You may not use the Site, Product or Services for any
                      purpose that is prohibited by the Terms or applicable law.
                      You will not (and will not permit any third party to) take
                      any action or make available any content on or through the
                      Site, Product, or Services that:
                    </span>
                  </li>
                  <br />
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        (a) Violates any applicable laws or regulations through
                        your access to or use of the Services or the Site;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>(b) Violates these Terms of Use;</span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (c) Exploits the Services or the Site for any
                        unauthorised commercial purpose;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (d) Harvests or otherwise collect information from the
                        Services or the Site for any unauthorized purpose;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (e) Uses the Site in any manner that could disable,
                        overburden, damage, or impair the Site or interfere with
                        any other party&rsquo;s use or enjoyment of the Site;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (f) Reverse engineers, disassembles, or decompiles the
                        Services or the Site or applies any other process or
                        procedure to derive the source code of any software
                        included in the Services or the Site except to the
                        extent applicable law does not allow this restriction or
                        such rights have been expressly granted to you under a
                        separate license;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (g) Sublicenses, sells, or otherwise distributes the
                        Services or the Site, or any portion thereof;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (h) Uses any data mining tools, robots, crawlers, or
                        similar data gathering and extraction tools to scrape or
                        otherwise remove data from the Services or the Site;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (i) Uses any manual process to monitor or copy any of
                        the material on the Site or for any other unauthorized
                        purpose without our prior written consent;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (j) Introduces any viruses, trojan horses, worms, logic
                        bombs, or other material which is malicious or
                        technologically harmful to the Services or the Site;
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (k) Attempts to gain unauthorised access to, interfere
                        with, damage, or disrupt any parts of the Site, the
                        server(s) on which the Site is stored, or any server,
                        computer or database connected to the Site; or
                      </span>
                      <br />
                      &nbsp;
                    </li>
                    <li>
                      <span>
                        (l) Attacks the Services or the Site via a
                        denial-of-service attack or a distributed
                        denial-of-service attack or otherwise attempt to
                        interfere with the proper working of the Site.
                      </span>
                      <br />
                      &nbsp;
                    </li>
                  </ol>
                  <li>
                    <strong>4.2</strong>
                    <span>
                      {" "}
                      If CIFDAQ becomes aware of any possible violations by you
                      of any provision of the Terms, CIFDAQ may investigate such
                      violations, and at its sole discretion may take any of the
                      actions set forth in Section 8 below.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>

            <ol start="5">
              <li>
                <span>
                  <strong>5. Indemnification. </strong>
                </span>
                <ol>
                  <li>
                    <span>
                      <strong>5.1</strong> You agree to indemnify and hold
                      CIFDAQ harmless from any losses, costs, liabilities, and
                      expenses (including reasonable attorneys&rsquo; fees)
                      relating to or arising out of:
                    </span>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        <span>
                          (a) your use of, or inability to use, the Site, the
                          Product, or Services;
                        </span>
                      </li>
                      <li>
                        <span>(b) your violation of the Terms;</span>
                      </li>
                      <li>
                        <span>
                          (c) your violation of any rights of another party,
                          including any other users of the Product, Site, or
                          Services; or
                        </span>
                      </li>
                      <li>
                        <span>
                          (d) your violation of any applicable laws, rules, or
                          regulations.{" "}
                        </span>
                      </li>
                    </ol>
                    &nbsp;
                  </li>
                  <li>
                    <span>
                      <strong>5.2</strong> CIFDAQ may, at its own cost, assume
                      the exclusive defence and control of any matter otherwise
                      subject to indemnification by you, in which event you will
                      fully cooperate with CIFDAQ in asserting any available
                      defences.{" "}
                    </span>
                  </li>
                </ol>
              </li>
            </ol>

            <p className="ml-24 text-justify">
              <span></span>
            </p>

            <ol start="6">
              <li>
                <span>
                  <strong>6. Disclaimer of Warranties. </strong>
                </span>
                <ol>
                  <li>
                    <span>
                      <strong>6.1</strong>
                      <em> As Is Basis</em>{" "}
                    </span>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        <span>
                          {" "}
                          The software, site, services, and any information
                          displayed therein are provided on an “AS IS” and “AS
                          AVAILABLE” basis, with all faults, and CIFDAQ
                          expressly disclaims all warranties, representations,
                          and conditions of any kind arising from or related to
                          these terms or your use of the site, services,
                          software, or any information displayed therein,
                          including the implied warranties of merchantability
                          and fitness for a particular purpose. You acknowledge
                          that, to the extent allowed by applicable law, all
                          risk of use of the site, services, and product rests
                          entirely with you.
                        </span>
                      </li>
                    </ol>
                    &nbsp;
                  </li>
                </ol>
                <ol>
                  <li>
                    <span>
                      <strong>6.2</strong>
                      <em> Beta Release</em>
                    </span>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        <span>
                          {" "}
                          From time to time, CIFDAQ may offer new “beta”
                          features or tools with which its users may experiment.
                          Such features or tools are offered solely for
                          experimental purposes, without any warranty of any
                          kind, and may be modified or discontinued at CIFDAQ’
                          sole discretion.
                        </span>
                      </li>
                    </ol>
                    &nbsp;
                  </li>
                </ol>
                <ol>
                  <li>
                    <span>
                      <strong>6.3</strong>
                      <em> Third Party Conduct</em>
                    </span>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        <span>
                          {" "}
                          CIFDAQ products or site may include links to third
                          party sites, products, or services.  You agree not to
                          hold CIFDAQ liable for the conduct of third parties on
                          or accessed via the Product, Site, Or Services,
                          including the use of Third-Party APIs and or services.
                          The risk of injury from use of such third-party
                          software and services rests entirely with you.
                        </span>
                      </li>
                    </ol>
                    &nbsp;
                  </li>
                </ol>
              </li>
            </ol>

            {/*   <p><br />
                            <span>The software, site, services, and any information displayed therein are provided on an &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; basis, with all faults, and CIFDAQ expressly disclaims all warranties, representations, and conditions of any kind arising from or related to these terms or your use of the site, services, software, or any information displayed therein, including the implied warranties of merchantability and fitness for a particular purpose. You acknowledge that, to the&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; extent allowed by applicable law, all&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; risk of&nbsp; use of the site, services, and product rests entirely with you.</span><br />
                            &nbsp;</p>
 */}

            <ol start="7">
              <li>
                <span>
                  <strong>7. Limitation of Liability </strong>
                </span>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        {" "}
                        <strong>1. </strong> In no event will CIFDAQ be liable
                        for any lost profits, revenue or data, indirect,
                        incidental, special, or consequential damages, or
                        damages or costs due to loss of production or use,
                        business interruption, or procurement of substitute
                        goods or services arising out of or in connection with
                        the services, whether or not CIFDAQ has been advised of
                        the possibility of such damages, and regardless of the
                        theory of liability asserted.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>2. </strong> Under the maximum extent permitted
                        by law, the maximum liability of CIFDAQ shall be One
                        Thousand American dollars ($1,000.00).
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
              </li>
            </ol>

            <ol start="8">
              <li>
                <span>
                  <strong>8. Term and Termination</strong>
                </span>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>8.1</strong> The Terms commence on the date when
                        you accept them (as described in the preamble above) and
                        remain in full force and effect for so long as you
                        access or use the
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>8.2</strong> CIFDAQ may, at any time and for any
                        reason, cease providing any or all of the Software,
                        Site, or Services, and/or terminate the Terms. Without
                        limiting the foregoing, we may also terminate your
                        access to any or all of the CIFDAQ content.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>8.3</strong> Except as set forth in the
                        following, these Terms will be of no further force and
                        effect with respect to you if you cease all use of the
                        Services and Software and no longer visit the Site.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>8.4</strong> Upon termination of any Service,
                        your right to use the Site, the Products and Service
                        will automatically terminate immediately. CIFDAQ will
                        not have any liability whatsoever to you for any
                        suspension or termination. All provisions of the Terms
                        which by their nature should survive termination of
                        Services will do so.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
              </li>
            </ol>

            <ol start="9">
              <li>
                <span>
                  <strong>
                    9.Dispute Resolution. Please read this Section (the
                    “Arbitration Agreement”) carefully. It requires you to
                    arbitrate disputes with CIFDAQ and limits the manner in
                    which you can seek relief.
                  </strong>
                </span>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>9.1</strong> You agree that any dispute or claim
                        relating in any way to your access or use of the Site,
                        Services, or Product, or to any aspect of your
                        relationship with CIFDAQ, will be resolved by binding
                        arbitration, rather than in court, except that:
                      </span>
                    </li>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        <span>
                          (a) You may assert claims in small claims court if
                          your claims qualify, so long as the matter remains in
                          such court and advances only on an individual
                          (non-class, non-representative) basis; and{" "}
                        </span>
                      </li>
                      <li>
                        <span>
                          (b) CIFDAQ may seek any relief (including but not
                          limited to injunctive orders) in any appropriate court
                          for infringement or other misuse of the services or
                          intellectual property rights (such as trademarks,
                          trade dress, domain names, trade secrets, copyrights,
                          and patents);
                        </span>
                      </li>
                    </ol>
                    &nbsp;
                  </ol>
                  &nbsp;
                </li>
                <p>
                  <strong>
                    This Arbitration Agreement will apply, without limitation,
                    to all claims that arose or were asserted before the
                    Effective Date of this Agreement or any prior version of
                    this Agreement
                  </strong>
                </p>
                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>9.2</strong> To the fullest extent permitted by
                        applicable law, the parties each agree that any
                        proceeding to resolve any dispute, claim, or controversy
                        will be brought and conducted only in the respective
                        party’s individual capacity and not as part of any class
                        (or purported class), consolidated, multiple plaintiffs,
                        or representative action or proceeding (“class action”).
                        The parties agree to waive the right to participate as a
                        plaintiff or class member in any class action. The
                        parties expressly waive any ability to maintain a class
                        action in any forum. If the dispute, claim, or
                        controversy is subject to Arbitration, the Arbitrator
                        will not have the authority to combine or aggregate
                        claims, conduct a class action, or make an award to any
                        person or entity not a party to the arbitration.
                        Further, the parties agree that the Arbitrator may not
                        consolidate proceedings for more than one person’s
                        claims, and it may not otherwise preside over any form
                        of a class action. For the avoidance of doubt, however,
                        you can seek public injunctive relief to the extent
                        authorized by law. If this class action waiver is
                        limited, voided, or found unenforceable, then, unless
                        the parties mutually agree otherwise, the parties’
                        Agreement to Arbitrate will be deemed null and void with
                        respect to such proceeding so long as the proceeding is
                        permitted to proceed as a class action. If a court
                        decides that the limitations of this paragraph are
                        deemed invalid or unenforceable, any putative class,
                        private attorney general, or consolidated or
                        representative action must be brought in a court of
                        proper jurisdiction and not in Arbitration.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>9.3</strong> This Agreement and all claims
                        arising under and in connection with this Agreement
                        shall be governed by the substantive laws of British
                        Virgin Island.{" "}
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>9.4</strong> Any dispute, controversy or claim
                        arising out of or relating to this Agreement, or the
                        breach, termination, or invalidity thereof brought by
                        you, shall be settled by arbitration in accordance with
                        the London Court of International Arbitration (the
                        “LCIA”) in accordance with the LCIA rules in force at
                        the time of the commencement of the arbitration. The
                        seat of arbitration shall be London. The language of the
                        arbitration shall be English. The number of arbitrators
                        shall be one (1). We retain the right to bring any
                        Dispute against you in any venue or court of competent
                        jurisdiction, including the obtaining of injunctive
                        relief in the courts of any jurisdiction, as determined
                        in its sole discretion.{" "}
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>9.5</strong> The arbitrator, and not any
                        federal, state or local court or agency will have
                        exclusive authority to:
                      </span>
                    </li>
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      <li>
                        <span>
                          (a) Determine the scope and enforceability of this
                          Arbitration Agreement; and
                        </span>
                      </li>
                      <li>
                        <span>
                          (b) Resolve any dispute related to the interpretation,
                          applicability, enforceability, or formation of this
                          Arbitration Agreement including, but not limited to
                          any claim that all or any part of this Arbitration
                          Agreement is void or voidable.
                        </span>
                      </li>
                      <li style={{ paddingTop: 5 + "px" }}>
                        <span>
                          {" "}
                          The arbitration will decide the rights and
                          liabilities, if any, of you and CIFDAQ. The
                          arbitration proceeding will not be consolidated with
                          any other matters or joined with any other cases or
                          parties. The arbitrator will have the authority to
                          grant motions dispositive of all or part of any claim.
                          The arbitrator will have the authority to award
                          monetary damages and to grant any non-monetary remedy
                          or relief available to an individual under applicable
                          law, the arbitral forum’s rules, and the Agreement
                          (including the Arbitration Agreement). The arbitrator
                          will issue a written award and statement of decision
                          describing the essential findings and conclusions on
                          which the award is based, including the calculation of
                          any damages awarded. The arbitrator has the same
                          authority to award relief on an individual basis that
                          a judge in a court of law would have. The award of the
                          arbitrator is final and binding upon you and us.{" "}
                        </span>
                      </li>
                    </ol>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol>
                    <li>
                      <span>
                        <strong>9.6</strong> You and CIFDAQ hereby waive any
                        constitutional and statutory rights to sue in court and
                        have a trial in front of a judge or a jury. You and
                        CIFDAQ are instead electing that all claims and disputes
                        will be resolved by arbitration under this Arbitration
                        Agreement, except as specified above. An arbitrator can
                        award on an individual basis the same damages and relief
                        as a court and must follow this Agreement as a court
                        would. However, there is no judge or jury in
                        arbitration, and court review of an arbitration award is
                        subject to very limited review.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol>
                    <li>
                      <span>
                        <strong>9.7</strong> This Arbitration Agreement will
                        survive the termination of your relationship with
                        CIFDAQ.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol>
                    <li>
                      <span>
                        <strong>9.8</strong> If any part of this Arbitration
                        Agreement is found under the law to be invalid or
                        unenforceable, then such part will be of no force and
                        effect and will be severed and the remainder of the
                        Arbitration Agreement will continue in full force and
                        effect.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
              </li>
            </ol>

            <ol start="10">
              <li>
                <span>
                  <strong>10. General Provisions</strong>
                </span>
                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.1. Electronic Communications. </strong>
                        Communications between you and CIFDAQ use electronic
                        means, whether made via the Site or Services or sent via
                        e-mail, or whether CIFDAQ posts notices on the Site or
                        Services. For contractual purposes, you (1) consent to
                        receive communications from CIFDAQ in an electronic
                        form; and (2) agree that all terms and conditions,
                        agreements, notices, disclosures, and other
                        communications that CIFDAQ provides to you
                        electronically satisfy any legal requirement that such
                        communications would satisfy if it were to be in
                        writing. The foregoing does not affect your statutory
                        rights.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.2. Assignment. </strong>The Terms, and your
                        rights and obligations hereunder, may not be assigned,
                        subcontracted, delegated, or otherwise transferred by
                        you without CIFDAQ&rsquo; prior written consent.{" "}
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.3. Force Majeure. </strong>CIFDAQ will not be
                        liable for any delay or failure to perform resulting
                        from causes outside its reasonable control, including,
                        but not limited to, acts of God, war, terrorism, riots,
                        embargos, acts of civil or military authorities, fire,
                        floods, accidents, strikes or shortages of
                        transportation facilities, fuel, energy, labour, or
                        materials.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.4. Questions, Complaints, Claims. </strong>
                        If you have any questions, complaints or claims with
                        respect to the Site, Services or Product, please contact
                        us at:{" "}
                        <a href="mailto:legal@cifdaq.com">
                          legal@cifdaq.com.{" "}
                        </a>{" "}
                        We will do our best to address your concerns.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.5. Notice. </strong>Where CIFDAQ requires
                        that you provide an e-mail address, you are responsible
                        for providing CIFDAQ with your most current e-mail
                        address. In the event that the last e-mail address you
                        provided to CIFDAQ is not valid, or for any reason is
                        not capable of delivering to you any notices required/
                        permitted by the Terms, CIFDAQ&rsquo;s dispatch of the
                        e-mail containing such notice will nonetheless
                        constitute effective notice. You may give notice to
                        CIFDAQ at the following address: Skelton Bay Lot, Fish
                        Bay, Tortola, VG 1110, British Virgin Island or at{" "}
                        <a href="mailto:legal@cifdaq.com">legal@cifdaq.com. </a>
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        Such notice will be deemed given when received by CIFDAQ
                        by letter delivered by nationally recognized overnight
                        delivery service or first-class postage prepaid mail at
                        the above address.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.6. Waiver.</strong> Any waiver or failure to
                        enforce any provision of the Terms on one occasion will
                        not be deemed a waiver of any other provision or of such
                        provision on any other occasion
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>

                <li>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span>
                        <strong>10.7. Severability.</strong> If any portion of
                        these Terms is held invalid or unenforceable, that
                        portion will be construed in a manner to reflect, as
                        nearly as possible, the original intention of the
                        parties, and the remaining portions will remain in full
                        force and effect.
                      </span>
                    </li>
                  </ol>
                  &nbsp;
                </li>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
