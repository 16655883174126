import React, { useState, useEffect } from 'react';
import API from "../services/Api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../components/LoaderComponent'
import { search } from "../services/search.service";

const SearchSeactionPage = (props) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [allValid, setAllValid] = useState(true)
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchDetails = useSelector((state) => state.search.searchDetails);

    function naviGateTo(res, param) {
        if (res === 'address') {
            navigate(`/address/${param.address}`);
        } else if (res === 'transaction') {
            navigate(`/tx/${param.hash}`);
        } else if (res === 'block') {
            navigate(`/block/${param.number}`);
        }
    }

    const handleChange = event => {
        if (event.target.value === '') {
            setErrorMessage("Please enter wallet address or transaction hash to search");
        } else {
            setSearchKeyword(event.target.value);
        }

    };


    const getResult = async () => {
        try {
            setAllValid(false);
            setIsLoading(true);
            setErrorMessage('');
            if (searchKeyword.trim().length !== 0) {
                dispatch(search(searchKeyword))
                setSearchKeyword('');
                // const response = await API.get(`cifdscan/search/${searchKeyword}`);
                // props.updateResultData(response?.data?.result);
                // naviGateTo(response?.data?.type, response?.data?.result);

            } else {
                setSearchKeyword('');
                setErrorMessage("Please enter valid wallet address, transaction hash or block number to search");
                setAllValid(true);
                setIsLoading(false);
            }

        } catch (err) {
            setSearchKeyword('');
            setErrorMessage("Please enter valid wallet address, transaction hash or block number to search");
            setAllValid(true);
            setIsLoading(false);
        }
        setIsLoading(false);
        setSearchKeyword('');

    };

    useEffect(() => {
        if (searchDetails?.success === true) {
            naviGateTo(searchDetails?.type, searchDetails?.result);
        }
        if (searchDetails?.success === false) {
            setErrorMessage("Please enter valid wallet address, transaction hash or block number to search");
        }
    }, [searchDetails]);
        


    const handleKeyDown = event => {

        if (event.key === 'Enter') {
            getResult();
        } else {
            setErrorMessage('');
        }

    };
    console.log('searchDetails', searchDetails);
    return (
        <>
            <div className="search_data">
                <div className="search_bar">
                    <input id="keywords"
                        name="keywords"
                        type="text"
                        // disabled={!allValid}
                        onChange={e => setSearchKeyword(e.target.value)}
                        value={searchKeyword}
                        onKeyDown={handleKeyDown}
                        placeholder="Search by Address / Txn Hash / Block" />
                    {searchDetails?.isLoading &&
                        <img src="/img/loader/loader.gif" style={{ margin: 'auto', display: 'block', alt: 'Loading' }} />
                    }{
                        searchDetails.isLoading == false &&
                        <img onClick={getResult} src="img/search-icon.svg" alt="" />
                    }
                    {errorMessage && <div className="error"> {errorMessage} </div>}
                </div>
            </div>

            {<div className="list_type">
                <p>Search examples : </p>
                <ul>
                    <li><a style={{ cursor: "default" }}><img src="/img/search_mailbox.svg" alt="" />Address</a></li>
                    <li><a style={{ cursor: "default" }}><img src="/img/search_block.svg" alt="" />Block</a></li>
                    <li><a style={{ cursor: "default" }}><img src="/img/search_transaction_hash.svg" alt="" />Transactions Hash</a></li>
                </ul>
            </div>}


            <div className="bg_globe">
                <img src="/img/background_globe.svg" alt="" />
            </div>

        </>
    )

}

export default SearchSeactionPage;