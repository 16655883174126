import React, { useEffect, useRef, useState } from 'react';

const CifdPrice = () => {
  const [liveData, setLiveData] = useState('');
  const [closeMessage, setCloseMessage] = useState('');

  const ws = useRef(null);

  useEffect(() => {
    const wsUrl = 'wss://ws.coinstore.com/s/ws';
    const tickerSubscription = {
      op: 'SUB',
      channel: ['CIFDUSDT@ticker'],
      id: 1,
    };

    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
      ws.current.send(JSON.stringify(tickerSubscription));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message:', message);

      if (message.close) {
        setLiveData(message.close); // Update state with the latest 'close' data
        console.log(message.close,"CIFD Price");
      }

      if (message.T === 'ticker') {
        const tickerData = message.data;
        // console.log('Ticker Data:', tickerData);
        // You can update state or trigger actions based on received data
      }
    };

    ws.current.onclose = (event) => {
      console.log('WebSocket connection closed');
      setCloseMessage(`Connection closed with code: ${event.code}. Reason: ${event.reason}`);
      // Handle closed connection or implement reconnection logic if needed
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error.message);
      // Handle errors that occur in the WebSocket connection
    };

    // Clean up the WebSocket connection when the component is unmounted
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  return (
    <div>
      
        <p>{liveData} USDT</p>
        

      
    </div>
  );
};

export default CifdPrice;
